import { useState, useRef, useEffect, useCallback } from 'react';
import Link from 'next/link';
import Image from 'next/legacy/image';

import { Popover } from '@headlessui/react';
import { useRouter } from 'next/router';
import Button from '../elements/Button';
// import LogoTextImg from '@crone/shared/shared-dupe/logos/logo-text-min.png';
// import LogoSquareImg from '@crone/shared/shared-dupe/logos/logo.png';
import LogoSquareImg from '@crone/shared/shared-dupe/logos/logo.png';
import { SIGNIN_PATH } from '@crone/shared/config/constants';
import { AppleIcon } from '../graphics/AppleIcon';
import { APP_STORE_LINK } from '@crone/shared/config/constants';

interface Props {
  navDark?: boolean;
  hideUntilScroll?: boolean;
}

export default function VisitorNavbar({
  navDark = false,
  hideUntilScroll = false,
}: Props) {
  const hamburgerNode = useRef<any | null>(null);
  const [navbarOpen, setNavbarOpen] = useState(false);

  const router = useRouter();

  const handleScroll = useCallback(() => {
    // only show navbar after user scrolls down 100px
    if (window.scrollY > 100) {
      document?.getElementById('navbar')?.classList.add('opacity-100');
      document?.getElementById('navbar')?.classList.remove('opacity-0');
    } else {
      document?.getElementById('navbar')?.classList.remove('opacity-100');
      document?.getElementById('navbar')?.classList.add('opacity-0');
    }
  }, []);

  useEffect(() => {
    if (hideUntilScroll) {
      handleScroll();
      window.addEventListener('scroll', handleScroll);
    } else {
      document?.getElementById('navbar')?.classList.add('opacity-100');
      document?.getElementById('navbar')?.classList.remove('opacity-0');
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, hideUntilScroll]);

  let navbarClasses =
    'shadow shadow-md border-b-2 border-solid border-gray-300 justify-between transition-all duration-300 ease-in-out';

  navbarClasses += navDark || navbarOpen ? ' bg-gray-50' : ' bg-white';
  navbarClasses += hideUntilScroll ? ' fixed top-0 left-0 right-0 z-50' : '';

  return (
    <Popover className="relative">
      {() => (
        <>
          <nav id="navbar" className={navbarClasses}>
            <div className={`mx-auto max-w-6xl px-6 w-full`}>
              <div className="flex items-center justify-between h-16 sm:px-4 relative">
                <div className="space-x-5 md:space-x-12 flex flex-row items-center">
                  <Link href="/" className="mt-2">
                    <div className="block">
                      <Image
                        className="h-10 w-10"
                        src={LogoSquareImg}
                        height={40}
                        width={40}
                        alt="Scrivium"
                      />
                    </div>
                    {/* <div className="block lg:hidden">
                      <Image
                        className="w-auto h-8 sm:h-10"
                        src={LogoSquareImg}
                        alt="Scrivium"
                        height={40}
                        width={40}
                      />
                    </div> */}
                  </Link>
                  <Link href="/library" className="crone-visitor-navbar-link">
                    Library
                  </Link>

                  <Link
                    href={APP_STORE_LINK}
                    target="_blank"
                    className="crone-visitor-navbar-link"
                  >
                    iPhone
                  </Link>
                  {/* <Link href="/pricing" className="crone-visitor-navbar-link">
                    Scrivium Premium
                  </Link> */}
                </div>
                {/* Web */}
                <div className="hidden text-gray-800 font-medium text-base md:flex md:items-center md:space-x-6">
                  <Link
                    href={{ pathname: SIGNIN_PATH, query: router.query }}
                    className="crone-visitor-navbar-link"
                  >
                    Sign in
                  </Link>
                  <Button
                    presetStyle="prompt-response"
                    appendClasses="inline-flex text-base"
                    onClick={() => {
                      router.push({
                        pathname: '/welcome',
                        query: router.query,
                      });
                    }}
                  >
                    Get started free
                  </Button>
                </div>

                {/* Mobile */}
                <div
                  className={`flex -mr-2 space-x-1 md:hidden`}
                  ref={hamburgerNode}
                >
                  <button
                    onClick={() => {
                      window.open(APP_STORE_LINK, '_blank');
                    }}
                    className="inline-flex items-center justify-center p-2 text-gray-600 rounded hover:text-gray-900 hover:bg-sky-100 focus:outline-none focus:bg-gray-200 focus:text-gray-600"
                  >
                    <AppleIcon size={25} />
                  </button>
                  <button
                    onClick={() => {
                      setNavbarOpen(!navbarOpen);
                    }}
                    className="inline-flex items-center justify-center p-2 text-gray-600 rounded hover:text-gray-900 hover:bg-sky-100 focus:outline-none focus:bg-gray-200 focus:text-gray-600"
                  >
                    <svg
                      className="w-6 h-6"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      {navbarOpen ? (
                        <path
                          className="inline-flex"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      ) : (
                        <path
                          className="inline-flex"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M4 6h16M4 12h16M4 18h16"
                        />
                      )}
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            {navbarOpen && (
              <div className="pt-4 pb-3 border-t border-gray-200 w-screen">
                <div className="flex flex-col px-10 pb-5 pt-2 gap-4">
                  {/* <Button
                    buttonText="Library"
                    presetStyle="default-subtle"
                    appendClasses="w-full"
                    onClick={() => {
                      router.push({
                        pathname: '/library',
                        query: router.query,
                      });
                    }}
                  /> */}

                  <Button
                    buttonText="Get started free"
                    presetStyle="gradient-button"
                    appendClasses="w-full"
                    onClick={() => {
                      router.push({
                        pathname: '/welcome',
                        query: router.query,
                      });
                    }}
                  />
                  <Button
                    buttonText="I have an account"
                    presetStyle="prompt-response"
                    appendClasses="w-full"
                    onClick={() => {
                      router.push({
                        pathname: SIGNIN_PATH,
                        query: router.query,
                      });
                    }}
                  />
                </div>
              </div>
            )}
          </nav>
        </>
      )}
    </Popover>
  );
}
