// import Link from 'next/link';

import { useAuth } from '@crone/shared/hooks/useAuth';
import Link from 'next/link';
import { DISCORD_LINK } from '@crone/shared/config/constants';
import {
  TbBrandDiscord,
  TbBrandInstagram,
  TbBrandTiktok,
  TbBrandTwitter,
} from 'react-icons/tb';

// comment to push

const Footer = (): JSX.Element => {
  const { user } = useAuth();
  return (
    <footer>
      <div className="max-w-screen-xl mx-auto pt-48 pb-8 px-4 overflow-hidden sm:px-6 lg:px-8">
        {!user && (
          <nav className="-mx-5 -my-2 flex flex-wrap justify-center space-x-5 lg:space-x-12 text-sm md:text-base leading-6 text-gray-600">
            <Link href="/library" className="hover:text-gray-800">
              Library
            </Link>
            <Link href="/terms" className="hover:text-gray-800">
              Terms
            </Link>

            <Link href="/privacy" className="hover:text-gray-800">
              Privacy
            </Link>
            <Link href="/pricing" className="hover:text-gray-800">
              Patrons
            </Link>
            {/* <div className="py-2">
              <Link href="/pricing">
                <a className="hover:text-gray-800">Pricing</a>
              </Link>
            </div> */}
            {/* <div className="px-5 py-2">
            <a
              href="#team"
              className="text-base leading-6 text-gray-600 hover:text-gray-800"
            >
              Team
            </a>
          </div> */}
          </nav>
        )}
        <div className="mt-8 flex flex-row space-x-8 md:space-x-12 text-2xl justify-center">
          {/* <a
            href="https://www.facebook.com/scrivium"
            target={'_blank'}
            className="text-gray-400 hover:text-gray-500"
            rel="noreferrer"
          >
            <span className="sr-only">Facebook</span>
            <TbBrandFacebook />
          </a> */}
          <a
            href="https://www.tiktok.com/@scrivium"
            target={'_blank'}
            className="text-gray-400 hover:text-gray-500"
            rel="noreferrer"
          >
            <span className="sr-only">TikTok</span>
            <TbBrandTiktok />
          </a>
          <a
            href="https://www.instagram.com/scrivium/"
            target={'_blank'}
            className="text-gray-400 hover:text-gray-500"
            rel="noreferrer"
          >
            <span className="sr-only">Instagram</span>
            <TbBrandInstagram />
          </a>
          <a
            href="https://x.com/scrivium"
            className="text-gray-400 hover:text-gray-500"
            target={'_blank'}
            rel="noreferrer"
          >
            <span className="sr-only">X</span>
            <TbBrandTwitter />
          </a>
          <a
            href={DISCORD_LINK}
            className="text-gray-400 hover:text-gray-500"
            target={'_blank'}
            rel="noreferrer"
          >
            <span className="sr-only">Discord</span>
            <TbBrandDiscord />
          </a>
          {/* <a
            href="https://www.linkedin.com/company/Scrivium/"
            className="text-gray-400 hover:text-gray-500"
            target={'_blank'}
            rel="noreferrer"
          >
            <span className="sr-only">LinkedIn</span>
            <TbBrandLinkedin />
          </a> */}
        </div>
      </div>
      <div className="text-gray-400 text-center pb-8 text-xs md:text-sm">
        Copyright 2025 by Crone Inc. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
