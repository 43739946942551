import { ReactNode } from 'react';
// import { useAuth } from '@crone/shared/hooks/useAuth';

// import VisitorNavbar from '@crone/shared/components/shared/VisitorNavbar';
import Footer from './Footer';
import VisitorNavbar from './VisitorNavbar';

interface Props {
  children: ReactNode;
  bgDark?: boolean;
  hideFooter?: boolean;
  noMargins?: boolean;
  centered?: boolean;
  hideUntilScroll?: boolean;
}

// TODO rename Layout to VisitorLayout
const Layout = ({
  bgDark,
  children,
  hideFooter,
  noMargins = false,
  centered = false,
  hideUntilScroll = false,
}: Props) => {
  // const auth = useAuth();

  // is this the home page? (path is /)
  // const router = useRouter();
  // const isHome = router.pathname === '/';

  return (
    <div
      id="CroneLayout"
      className={`min-h-screen ${bgDark ? 'bg-gray-50' : 'bg-transparent'}`}
    >
      <VisitorNavbar hideUntilScroll={hideUntilScroll} />
      <div
        className={`${noMargins ? '' : 'px-3'} ${
          centered ? 'max-w-6xl mx-auto' : ''
        }`}
      >
        {children as unknown as ReactNode}
      </div>
      {!hideFooter && <Footer />}
    </div>
  );
};

export default Layout;
